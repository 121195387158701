<template>
  <Page
    :title="$vuetify.breakpoint.mobile ? 'FAQ' : 'Frequently Asked Questions'"
    color="grey"
  >
    <template slot="extension">
      <v-tabs background-color="grey darken-1">
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            single-line
            dense
            hide-details
            label="Suche"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>
      </v-tabs>
    </template>

    <v-alert
      v-if="!search"
      type="info"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die FAQ-Sammlung umfasst {{ filteredItems.length }} Einträge.
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length == 0"
      type="error"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Suche nach «{{ search }}» ergab keinen Treffer!
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length > 0"
      type="success"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      {{ filteredItems.length }} Suchergebnis{{
        filteredItems.length > 1 ? 'se' : ''
      }}
      für «{{ search }}».
    </v-alert>

    <v-timeline :dense="$vuetify.breakpoint.xs">
      <v-timeline-item
        v-for="(item, index) in filteredItems"
        :key="index"
        fill-dot
        :icon="item.icon"
        right
      >
        <template v-slot:opposite>
          <span class="headline font-weight-bold primary--text">{{
            item.problem
          }}</span>
        </template>
        <div class="py-4">
          <span
            v-if="$vuetify.breakpoint.xs"
            class="headline font-weight-bold primary--text"
            >{{ item.problem }}</span
          >
          <h2 class="headline font-weight-light mb-2 primary--text">
            {{ item.solution }}
          </h2>
          <div v-html="item.explanation"></div>
        </div>
      </v-timeline-item>
    </v-timeline>
  </Page>
</template>

<script>
import Faq from './faq';

export default {
  data() {
    return {
      items: [],
      search: '',
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter((item) =>
        item.problem.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {},

  async created() {
    this.items = Faq.map((item) => ({
      ...item,
      show: false,
    }));
  },
};
</script>
