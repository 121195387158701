export default [
  {
    icon: 'mdi-theme-light-dark',
    problem: 'Wo ist der Dark-Mode?',
    solution: 'Den gibt es (noch) nicht.',
    explanation:
      'Viele Seiten basieren noch auf der alten Technologie. Wenn alles umgestellt ist, können wir über einen Dark-Mode reden.',
  },
  {
    icon: 'mdi-navigation',
    problem: 'Wo ist das Navigations-Menu?',
    solution: 'Klicke oben links auf das Gymer-Logo.',
    explanation:
      'Das Menu wird auf mobilen Geräten — und auch am Desktop ab einer gewissen Fensterbreite – aus Platzgründen automatisch ausgeblendet.',
  },
  {
    icon: 'mdi-login',
    problem: 'Wie logge ich mich ein?',
    solution:
      'Du wirst automatisch auf eine Microsoft-Login-Seite weitergeleitet.',
    explanation:
      'Dort kannst du dich mit deiner Gymer-E-Mail-Adresse und dem dazugehörenden Passwort anmelden.',
  },
  {
    icon: 'mdi-palette',
    problem: 'Wieso habt ihr das Design umgestellt?',
    solution: 'Weil wir auf eine neue Technologie umsteigen.',
    explanation:
      'Das alte Intern kam mit einer Server-seitigen App daher und es war aufwändig, auch nur kleine Anpassungen vorzunehmen. Die neue Intern-App ist eine richtige Web-App, die auf dem Client läuft sich vom Server nur noch Daten holt.',
  },
];
